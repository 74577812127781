/* h6 {
  color: #d3d3d3;
  font-size: 30px;
  letter-spacing: 1px;
}

h2 {
  font-size: 52px !important;
  color: #ffffff;
}
*/



a{
  text-decoration: none;
  color: var(--white) !important;
}

li{
  list-style-type: none;
}
  
#root{
  background-color: #EFEFEF;
}