* {
  margin: 0;
  padding: 0;
  font-family: "Work Sans", Arial, Helvetica, sans-serif;
  box-sizing: border-box;
}

*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}

body {
  color: var(--white);
}

ul {
  padding: 0 !important;
}
img {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
a {
  text-decoration: none !important;
  color: var(--white) !important;
}
