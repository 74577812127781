.contact {
  position: relative;
  z-index: 2;
  margin-bottom: 250px;
  background-color: var(--blue);
  padding: 60px 0 80px 0px;
  border-bottom-left-radius: 75px;
  border-bottom-right-radius: 75px;
}

.contact h3,
h4,
span {
  color: var(--white);
}

.contact .contact_right h3 {
  padding-bottom: 10px;
  padding-top: 40px;
  font-size: 27px;
}

.contact .social_media_title {
  font-size: 22px !important;
}

.contact .contact_footer_img img {
  width: 70%;
  padding-top: 120px;
}

.contact .contact_span a:hover {
  opacity: 0.55;
  font-style: italic;
}
.footer_alternative{
  background-color: #642530;
}
footer {
  position: fixed;
  background-color: #efefef;
  bottom: 0;
  width: 100%;
  height: 400px;
  padding-top: 20px;
  padding-bottom: 50px;
}

footer .white_section {
  background-color: #efefef;
}

footer .white_section a h3 {
  color: var(--blue);
}

footer .container .contact_right h3,
.contact_span {
  color: var(--white);
  font-size: 22px;
}
footer .footer_logo img {
  max-width: 200px;
  padding-top: 130px;
}

.map_iframe {
  width: 80%;
  height: 200px;
  border-radius: 20px;
}

@media screen and (max-width: 1024px) {
  .contact .contact_footer_img img {
    width: 100%;
  }
}

@media screen and (max-width: 992px) {
  .contact .contact_right {
    padding-bottom: 100px;
  }

  footer .footer_logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  footer .footer_logo img {
    max-width: 200px;
  }
}

@media screen and (max-width: 768px) {
  footer .footer_logo img {
    max-width: 200px;
  }

  .map_iframe {
    width: 100%;
    height: 200px;
    border-radius: 20px;
  }
}

footer li {
  padding: 5px 0;
}
footer ul {
  padding: 5px 0;
}

footer ul,
li {
  color: var(--blue);
}

footer .white_section h3 {
  font-weight: 600;
}

footer .white_section li {
  font-size: 20px;
}

@media screen and (max-width: 568px) {
  .contact {
    /* padding: 100px 0 80px 0px; */
  }

  .contact .contact_footer_img img {
    display: none;

    padding-top: 50px;
  }

  footer .footer_logo img {
    max-width: 200px;
  }
}

@media screen and (max-width: 400px) {
  footer .footer_logo img {
    max-width: 200px;
  }
}

@media screen and (max-width: 450px) {
  footer .footer_logo img {
    max-width: 200px;
  }
}

.our_story h3 {
  color: var(--blue);
  font-style: italic;
  font-size: 16px;
}

.our_story .footer_arrow {
  font-size: 20px;
  color: var(--blue);
  padding-left: 5px;
}

.our_story a:hover {
  opacity: 0.55;
}

footer .footer_icon a .footer_icon_inner {
  color: var(--blue) !important;
}

.footer_icon a .footer_icon_inner:hover {
  opacity: 0.55;
}

.footer_icon .footer_social_media_icon {
  font-size: 22px;
  padding: 8px 8px 8px 8px;
}

.contact .location_gif {
  width: 40%;
}
