@keyframes opacity-increaser {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes opacity-decreaser {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes rotater {
  0% {
    transform: rotate(0);
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
  }

  100% {
    transform: rotate(2deg);
    -webkit-transform: rotate(2deg);
    -moz-transform: rotate(2deg);
    -ms-transform: rotate(2deg);
    -o-transform: rotate(2deg);
  }
}
@keyframes rotater2 {
  0% {
    transform: rotate(0);
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
  }

  100% {
    transform: rotate(4deg);
    -webkit-transform: rotate(4deg);
    -moz-transform: rotate(4deg);
    -ms-transform: rotate(4deg);
    -o-transform: rotate(4deg);
  }
}
@keyframes rotater3 {
  0% {
    transform: rotate(0);
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
  }

  100% {
    transform: rotate(-1deg);
    -webkit-transform: rotate(-1deg);
    -moz-transform: rotate(-1deg);
    -ms-transform: rotate(-1deg);
    -o-transform: rotate(-1deg);
  }
}
@keyframes rotater4 {
  0% {
    transform: rotate(0);
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
  }

  100% {
    transform: rotate(-3deg);
    -webkit-transform: rotate(-3deg);
    -moz-transform: rotate(-3deg);
    -ms-transform: rotate(-3deg);
    -o-transform: rotate(-3deg);
  }
}
@keyframes rotater5 {
  0% {
    transform: rotate(0);
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
  }

  100% {
    transform: rotate(-6deg);
    -webkit-transform: rotate(-6deg);
    -moz-transform: rotate(-6deg);
    -ms-transform: rotate(-6deg);
    -o-transform: rotate(-6deg);
  }
}
@keyframes rotater6 {
  0% {
    transform: rotate(0);
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
  }

  100% {
    transform: rotate(7deg);
    -webkit-transform: rotate(7deg);
    -moz-transform: rotate(7deg);
    -ms-transform: rotate(7deg);
    -o-transform: rotate(7deg);
  }
}
@keyframes rotater7 {
  0% {
    transform: rotate(0);
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
  }

  100% {
    transform: rotate(1deg);
    -webkit-transform: rotate(1deg);
    -moz-transform: rotate(1deg);
    -ms-transform: rotate(1deg);
    -o-transform: rotate(1deg);
  }
}

@keyframes fadeDownWithScale {
  0% {
    top: 100%;
    transform: scale(1.3);
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -ms-transform: scale(1.3);
    -o-transform: scale(1.3);
  }
  100% {
    top: 10%;
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
  }
}
@keyframes fadeUpWithScale {
  0% {
  }
  20% {
    top: 0%;
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
  }
  100% {
    top: 200%;
    transform: scale(1.3);
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -ms-transform: scale(1.3);
    -o-transform: scale(1.3);
  }
}

@keyframes infoTextAnim {
  0% {
    opacity: 0;
    /* filter: blur(4px); */
    /* -webkit-filter: blur(4px); */
    top: 25px;
  }
  100% {
    opacity: 1;
    /* filter: blur(0px); */
    /* -webkit-filter: blur(0px); */
    top: 0px;
  }
}

@media (width <= 768px) {
  @keyframes fadeDownWithScale {
    0% {
      top: 100%;
      transform: scale(1.3);
      -webkit-transform: scale(1.3);
      -moz-transform: scale(1.3);
      -ms-transform: scale(1.3);
      -o-transform: scale(1.3);
    }
    100% {
      top: 5%;
      transform: scale(1);
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
    }
  }
}
