header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 50;

}
header .main_logo_inner {
  width: 30%;
}

.header_bg{
  top: 48px;
  left: 50%;
  background-color: var(--blue);
  transition: all 0.5s ease;
  display: flex;
  justify-content: center;
  
}

header .header-logo {

  position: fixed;
  top: 48px;
  left: 50%;
  z-index: 89;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.header-container {
  display: none;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  /* flex-direction: row;*/
}

#menuBtn input:checked + .header-container {
  display: flex;
}

#menuBtn {
  position: absolute;
  top: 30px;
  left: 3em;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 99;
  height: auto;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
#menuBtn input {
  display: block;
  cursor: pointer;
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 9;
}
#menuBtn input:checked ~ span:nth-child(2) {
  opacity: 0;
}
#menuBtn input:checked ~ span:nth-child(3) {
  transform: rotate(45deg);
}
#menuBtn input:checked ~ span:nth-child(4) {
  display: block;
  transform: rotate(135deg);
  opacity: 1;
  margin-top: -3px;
}
#menuBtn input:checked ~ span:nth-child(5) {
  opacity: 0;
}
#menuBtn span {
  width: 35px;
  height: 3px;
  background: white;
  position: relative;
  display: block;
  margin: 4px 0;
  border-radius: 2px;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}
#menuBtn input ~ span:nth-child(2) {
  width: 17.5px;
  left: -50%;
  transform: translateX(50%);
  -webkit-transform: translateX(50%);
  -moz-transform: translateX(50%);
  -ms-transform: translateX(50%);
  -o-transform: translateX(50%);
}
#menuBtn:hover input ~ span:nth-child(2) {
  left: 0;
}
#menuBtn input:checked ~ span:nth-child(3) {
  width: 25px;
  margin-bottom: auto;
}
#menuBtn span:nth-child(4) {
  display: none;
  width: 25px;
  opacity: 0;
  margin: 0;
}
.header-container .left {
  height: 100vh;
  width: 50vw;
  position: relative;
  transition: all 0.4s linear;
  transform: translateY(100vh);

  background: url(../../../images/header/0.jpg);
  background-repeat: no-repeat;

  background-size: cover !important;
  -webkit-transform: translateY(100vh);
  -moz-transform: translateY(100vh);
  -ms-transform: translateY(100vh);
  -o-transform: translateY(100vh);
  -webkit-transition: all 0.4s linear;
  -moz-transition: all 0.4s linear;
  -ms-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
}
.header-container .right {
  height: 100vh;
  width: 100vw;

  transition: all 0.4s linear;
  transform: translateY(-100vh);
  -webkit-transform: translateY(-100vh);
  -moz-transform: translateY(-100vh);
  -ms-transform: translateY(-100vh);
  -o-transform: translateY(-100vh);
  background: var(--blue);
  display: flex;
  justify-content: center;
  align-items: end;
  flex-direction: column;

  padding: 40px 40px 40px 40px;

  -webkit-transition: all 0.4s linear;
  -moz-transition: all 0.4s linear;
  -ms-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
}

.header-container .right .wrapper-links {
  position: relative;
  left: 2em;
  display: flex;
  flex-direction: column;
}
.header-container .right a small,
.header-container .right a span {
  font-family: "Optima";
}
.header-container .right a {
  margin: 10px 0;
  text-decoration: none;
  font-weight: 400;
  font-size: 48px;
  color: inherit;
  color: var(--white);
  transition: opacity 0.3s ease-in 0.15s;
  -webkit-transition: opacity 0.3s ease-in 0.15s;
  -moz-transition: opacity 0.3s ease-in 0.15s;
  -ms-transition: opacity 0.3s ease-in 0.15s;
  -o-transition: opacity 0.3s ease-in 0.15s;
}
.header-container .right a small {
  font-size: 0.4em;
  margin-right: 1em;
}
.header-container .right a span {
  position: relative;
  left: 0;
  transition: left 0.2s ease-in;
  -webkit-transition: left 0.2s ease-in;
  -moz-transition: left 0.2s ease-in;
  -ms-transition: left 0.2s ease-in;
  -o-transition: left 0.2s ease-in;
}
.header-container .right a:hover span {
  left: 5%;
}

.home_paragraph {
  cursor: pointer;
  color: #ffffff;
  font-size: 48px;
  line-height: 70px;
  font-weight: 500;
}



@media screen and (max-width: 1025px) {
 
  .home_paragraph {
    font-size: 36px;
    line-height: 60px;
  }
}

@media screen and (max-width: 992px) {
  .header-container .right a {
    font-size: 40px;
  }
  .home_paragraph {
    font-size: 46px;
    line-height: 70px;
  }
}

@media screen and (max-width: 768px) {
  .header-container .right a {
    font-size: 38px;
  }
}

@media (width >= 768px) {
  .header-container .right {
    width: 50vw;
  }
}

@media (width <=576px) {
  #menuBtn {
    left: 0.5em;
  }
  .header-container .right .wrapper-links {
    left: 0;
  }

  .home_paragraph {
    font-size: 36px;
    line-height: 60px;
    /* margin-top: -80px; */
  }

  header .header-logo {
    left: 57%;
  }

  header .header-logo img {
    width: 90%;
  }
}


@media (width <=476px) {
  .home_paragraph {
    font-size: 30px;
    line-height: 50px;
    /* margin-top: -80px; */
  }
}

@media (width <=286px) {
  .home_paragraph {
    font-size: 20px;
    line-height: 40px;
    /* margin-top: -50px; */
  }
}