.divider {
  height: 3px;
  background-color: var(--blue);
  margin-bottom: 1em;
  opacity: 0.55;
}

.copy-rights {
    color: var(--blue);
  }
  .copy-rights .osi:hover {
    color: black;
  }
  
  .copy-rights .osi {
   display: inline-block;
  }
  
.copy-rights .osi h6{
  color: var(--blue);
}
  
.copy-rights .osi h6:hover{
  opacity: 0.55;
}

  @media screen and (max-width: 386px) {
    
    .copy-rights{
  
  font-size: 12px;
  
    }
  }
  