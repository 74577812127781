@font-face {
  font-family: "Optima";
  src: url("../../fonts/Optima/OptimaLTPro-Medium.otf") format("otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Optima";
  src: url("../../fonts/Optima/OptimaLTPro-Bold.otf") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Optima";
  src: url("../../fonts/Optima/OptimaLTPro-Roman.otf") format("woff");
  font-weight: lighter;
  font-style: normal;
}

/* @font-face {
  font-family: "Optima";
  src: url("../../fonts/Optima/optimaltpro-extrablack-webfont.woff") format("woff");
  font-weight: 900;
  font-style: normal;
} */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Optima";
}
