.contact_left{
    background-color: #EFEFEF;
    border-radius: 30px;

}
.contact_left form{
padding: 50px 50px 50px 50px;
}

.contact_left .form .form-control{
    background-color: #EFEFEF;
    padding: -1em;
    border: 0px !important;
    border-bottom: 3px solid var(--blue) !important;
    border-radius: 0px !important;
    opacity: 0.55;
}

.contact_left .form .form-control-message{
    padding: -1em;
    border: 0px !important;
    border: 3px solid var(--blue) !important;
    border-radius: 10px !important;
    margin-top: 15px;
    opacity: 0.55;
    background-color: #EFEFEF;

   
}


.contact_left .form label{
    margin-top: 20px;
    padding: -0.5em;
    color: var(--blue);
    font-size: 22px;
   
}

.contact_left h3{
    padding-top: 50px;
    color: var(--blue);
}

.contact_left .form-control:focus{
    box-shadow: 0 0 0 0 transparent !important;

}



.contact_left .form .display_grid{
    display: grid;
}

 .submit_button button {
    color: var(--blue);
    border-radius: 10px !important;
    border: 2px solid var(--blue) !important;
 
}

 .submit_button button:hover {
   background-color: var(--blue);
   color: var(--white);
}


@media screen and (max-width: 568px){
    .contact_left form{
        padding: 20px 20px 20px 20px;
        }

        .contact_left .form label{
           font-size: 17px;
        }

        .contact_right h3{
            font-size: 21px;
        }

        .contact_span{
            font-size: 17px;
        }
  }


  .form-group{
    padding: 2px;
}